import axios from '../../plugins/axios'
// 权限列表
const listControls = () => axios.get('/auth/auth-list-controls')
// 添加权限
const addControl = (options) => axios.post('/auth/auth-add-control', options)
// 删除权限
const deleteControl = (options) => axios.post(`/auth/auth-delete-control`, options)

// 角色列表
const listRoles = () => axios.get('/auth/auth-list-roles')
// 添加角色
const addRole = (options) => axios.post('/auth/auth-add-role', options)
// 修改角色
const updateRole = (options) => axios.post('/auth/auth-update-role', options)
// 删除角色
const deleteRole = (options) => axios.post(`/auth/auth-delete-role`, options)

// 用户列表
const listAccount = (options) => axios.get(`/account/account-list-${options.size}-${options.page}`)
// 添加用户
const addAccount = (options) => axios.post('/account/account-add', options)
// 获取账号
const getAccount = (options) => axios.get(`/account/account-get-${options.accountID}`)
// 更新账户
const updateAccount = (options) => axios.post('/account/account-update', options)
// 删除账户
const deleteAccount = (options) => axios.post(`/account/account-delete-${options.accountID}`, options)
// 查询账户角色
const getAccountRoles = (options) => axios.get(`/auth/auth-account-roles-${options.accountID}`)
// 角色与权限绑定
const addPermission = (options) => axios.post('/auth/auth-add-permission', options)
// 角色与权限绑定列表
const listPermissions = (options) => axios.get(`/auth/auth-list-permissions-${options.roleID}`, options)
// 账号与角色绑定
const addAccountRole = (options) => axios.post(`/auth/auth-add-account-role`, options)

// 查询API列表
const listApi = () => axios.get(`/api/auth/list-api-controls`)
// 查询API授权列表（列表页）
// eslint-disable-next-line no-irregular-whitespace
const listApiToken = (options) => axios.post(`/api/auth/list-api-token`, options)
// 查询API授权明细
const detailApi = (options) => axios.get(`/api/auth/list-api-token-detail-${options.apiTokenId}`)
// 修改API授权明细
const updateApi = (options) => axios.post(`/api/auth/update-api-token-detail`, options)
// 增加API授权
const addApi = (options) => axios.post(`/api/auth/save-api-token`, options)
// 启用/停用API授权
const changeApi = (options) => axios.get(`/api/auth/start-disable-api-token-${options.apiTokenId}-${options.deleteFlag}`)

export default {
  listControls,
  addControl,
  deleteControl,
  listRoles,
  addRole,
  deleteRole,
  listAccount,
  addAccount,
  getAccount,
  updateAccount,
  deleteAccount,
  getAccountRoles,
  addPermission,
  listPermissions,
  updateRole,
  addAccountRole,
  listApi,
  listApiToken,
  detailApi,
  updateApi,
  addApi,
  changeApi
}
