import LZString from 'lz-string'

const TokenKey = 'dreamkey-token'

const TokenKeyTime = 'dreamkey-tokenTime'

const UserInfo = 'ym-certificate-chain-userinfo'

const ActiveMenu = 'ym-certificate-chain-active-menu'

const ActiveApi = 'ym-certificate-chain-active-api'

const Init = 'init'

const ShardName = 'Shard'

const headerTitle = 'title'

const networkType = 'nwType'

const saveRefresh = 'refresh'

const savePath = 'path'

const deployMsg = 'deployMsg'

const deployBtn = 'deployBtn'

const guideList = 'guideList'

const _local = {

  // localStorage存储
  set(k, v) {
    if (!k) {
      // console.error('键值不合法. 无效的键值: ' + k)
      return
    }
    v = LZString.compress(JSON.stringify(v))
    window.localStorage.setItem(k, v)
  },

  // localStorage 获取数据
  get(k) {
    let item = window.localStorage.getItem(k)
    if (!item) {
      // console.log('本地未存储键值为: ' + k + ' 的值')
      return item
    }
    try {
      return JSON.parse(LZString.decompress(item))
    } catch (e) {
      // console.warn('LZString解密失败，直接返回原值')
      return item
    }
  },

  // localStorage删除数据
  remove(k) {
    window.localStorage.removeItem(k)
  },
  // localStorage清除全部
  clear() {
    window.localStorage.clear()
  }
}

const _session = {

  set(k, v) {
    if (!k) {
      console.error('键值不合法. 无效的键值: ' + k)
      return
    }
    v = LZString.compress(JSON.stringify(v))
    window.sessionStorage.setItem(k, v)
  },

  get(k) {
    let item = window.sessionStorage.getItem(k)
    if (!item) {
      // console.log('本地未存储键值为: ' + k + ' 的值')
      return item
    }
    try {
      return JSON.parse(LZString.decompress(item))
    } catch (e) {
      // console.warn('LZString解密失败，直接返回原值')
      return item
    }
  },

  remove(k) {
    window.sessionStorage.removeItem(k)
  },

  clear() {
    window.sessionStorage.clear()
  }
}

export function getGuide () {
  return _local.get(guideList)
}

export function setGuide (guide) {
  return _local.set(guideList, guide)
}

export function getDeployBtn () {
  return _session.get(deployBtn)
}

export function setDeployBtn (btn) {
  return _session.set(deployBtn, btn)
}

export function removeDeployBtn () {
  return _session.remove(deployBtn)
}

export function getDeployMsg () {
  return _session.get(deployMsg)
}

export function setDeployMsg (message) {
  return _session.set(deployMsg, message)
}

export function removeDeployMsg () {
  return _session.remove(deployMsg)
}

export function activeArray (control) {
  let activeArray = JSON.parse(getActiveApi())
  if (activeArray) {
    return activeArray.includes(control)
  }
}

export function activeMenu (menu) {
  let activeMenu = JSON.parse(getActiveMenu())
  if (activeMenu) {
    return activeMenu.includes(menu)
  }
}

export function getTokenName() {
  return TokenKey
}

export function getToken() {
  return _session.get(TokenKey)
}

export function setToken(token) {
  return _session.set(TokenKey, token)
}

export function removeToken() {
  return _session.remove(TokenKey)
}

export function getTokenTime() {
  return _session.get(TokenKeyTime)
}

export function setTokenTime(time) {
  return _session.set(TokenKeyTime, time)
}

export function removeTokenTime() {
  return _session.remove(TokenKeyTime)
}

export function getUserInfo() {
  return _session.get(UserInfo)
}

export function setUserInfo(info) {
  return _session.set(UserInfo, info)
}

export function removeUserInfo() {
  return _session.remove(UserInfo)
}

export function getActiveApi () {
  return _session.get(ActiveApi)
}

export function setActiveApi (active) {
  return _session.set(ActiveApi, active)
}

export function getActiveMenu() {
  return _session.get(ActiveMenu)
}

export function setActiveMenu(active) {
  return _session.set(ActiveMenu, active)
}

export function setInitStatus(status) {
  return _session.set(Init, status)
}

export function getInitStatus() {
  return _session.get(Init)
}

export function setShardName (shard) {
  return _session.set(ShardName, shard)
}

export function getShardName () {
  return _session.get(ShardName)
}

export function removeShardName () {
  return _session.remove(ShardName)
}

export function setNetworkType (nwType) {
  return _local.set(networkType, nwType)
}

export function getNetworkType () {
  return _local.get(networkType)
}

export function removeNetworkType () {
  return _local.remove(networkType)
}

export function setRefresh (refresh) {
  return _session.set(saveRefresh, refresh)
}

export function getRefresh () {
  return _session.get(saveRefresh)
}

export function setPath (path) {
  return _session.set(savePath, path)
}

export function getPath () {
  return _session.get(savePath)
}

export function removePath () {
  return _session.remove(savePath)
}

export function setHeaderTitle (title) {
  return _session.set(headerTitle, title)
}

export function getHeaderTitle () {
  return _session.get(headerTitle)
}

export {
  _local,
  _session
}
