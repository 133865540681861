/*  引入api接口  */
import api from '@/service'
/*  引入本地缓存函数  */
import { getGuide, setGuide } from '@/utils/localStorage.js'
import { MessageBox } from 'element-ui'
import { _session } from '@/utils/localStorage'

// 新手引导introJs
const myIntroJs = (pageName) => {
  // 根据缓存判断是否包含新手指引
  if (getGuide() === null || !JSON.parse(getGuide()).includes(pageName)) {
    // console.log(window.introData)
    // 判断配置是否有当前页面新手指引
    if (window.introData[pageName]) {
      // eslint-disable-next-line no-undef
      var intro = introJs()
      var list = []
      for (let item of window.introData[pageName]) {
        var obj = {}
        obj.step = item.step
        obj.title = item.title
        obj.intro = item.intro
        obj.highlightClass = item.highlightClass || ''
        obj.tooltipClass = item.tooltipClass || ''
        obj.tooltipPosition = item.tooltipPosition || ''
        // 高亮部位样式选取判断（当存在element1与菜单状态为1时，样式为element1）
        if (item.element1 && _session.get('sidebarStatus') === 1) {
          obj.element = document.querySelector(item.element1)
        } else {
          obj.element = document.querySelector(item.element)
        }
        if (item.highlightClass1 && _session.get('sidebarStatus') === 1) {
          obj.highlightClass = item.highlightClass1
        } else {
          obj.highlightClass = item.highlightClass || ''
        }
        if (item.tooltipClass1 && _session.get('sidebarStatus') === 1) {
          obj.tooltipClass = item.tooltipClass1
        } else {
          obj.tooltipClass = item.tooltipClass || ''
        }
        list.push(obj)
      }
      console.log(list)
      // introjs-nextbutton
      // introjs-prevbutton
      // 设置是否完成
      var isDone = false
      intro.setOptions({
        steps: list,
        /* 下一步按钮的显示名称 */
        nextLabel: '下一步',
        /* 上一步按钮的显示名称 */
        prevLabel: '上一步',
        /* 跳过按钮的显示名称 */
        skipLabel: '退出引导',
        /* 结束按钮的显示名称 */
        doneLabel: '完成',
        /* 说明高亮区域的样式 */
        // highlightClass: 'left-class',
        /* 引导说明文本框的样式 */
        // tooltipClass: 'left-class',
        /* 引导说明框相对高亮说明区域的位置 */
        // tooltipPosition: 'bottom',
        /* 隐藏第一步中的上一个按钮？否则，它将呈现一个禁用的按钮 */
        hidePrev: true,
        /* 隐藏最后一步的下一步按钮？否则，它将呈现一个禁用的按钮。（注意：这也会隐藏“完成”按钮） */
        hideNext: false,
        /* 是否允许点击空白处退出 */
        exitOnOverlayClick: true,
        /* 是否显示说明的数据步骤 */
        showStepNumbers: true,
        /* 是否滑动到高亮的区域 */
        scrollToElement: true,
        /* 禁用与突出显示框内元素的交互 */
        disableInteraction: true,
        /* 当位置选择自动的时候，位置排列的优先级 */
        positionPrecedence: ['bottom', 'top', 'right', 'left'],
        /* 默认提示位置 */
        hintPosition: 'top-middle'
      }).start().oncomplete((res) => {
        // 设置介绍完成时的回调
        console.log('oncomplete')
        isDone = true
      }).onbeforeexit(() => {
        // 在任何情况下只要退出即触发包括完成时的退出都会回调
        console.log('onbeforeexit')
        if (!isDone) {
          MessageBox.confirm('是否确定永久退出该页面的新手指引？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false
          }).then(() => {
            console.log('是')
            let options = {
              pageName: pageName
            }
            api.user.addGuide(options).then(res => {
              console.log(res)
              if (res.code === 200) {
                // 设置缓存指引
                let guideList = JSON.parse(getGuide()) || []
                guideList.push(pageName)
                setGuide(JSON.stringify(guideList))
              }
            })
            return true
          }).catch(() => {
            console.log('否')
            return true
          })
        } else {
          let options = {
            pageName: pageName
          }
          api.user.addGuide(options).then(res => {
            console.log(res)
            if (res.code === 200) {
              // 设置缓存指引
              let guideList = JSON.parse(getGuide()) || []
              guideList.push(pageName)
              setGuide(JSON.stringify(guideList))
            }
          })
        }
      })
    }
  }
}

export default {
  myIntroJs
}
