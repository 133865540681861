import axios from '../../plugins/axios'

// 获取列表数据
const listData = (options) => axios.get(`/dict/dict-list-${options.size}-${options.page}`)
// 分片查询
const shardData = (options, body) => axios.post(`/dict/dict-list-${options.size}-${options.page}`, body)
// 新增数据
const addDictionary = (options) => axios.post('/dict/dict-add', options)
// 编辑数据
const updateDictionary = (options) => axios.post('/dict/dict-update', options)
// 删除数据
const deleteDictionary = (options) => axios.post('/dict/dict-delete', options)

export default {
  listData,
  shardData,
  addDictionary,
  updateDictionary,
  deleteDictionary
}
