import Vue from 'vue'
import '@/plugins/axios'
import App from './App.vue'
import router from './router'
import store from '@/store'
import '@/utils/permission'
import GlobalConfig from '@/config'
import '@/plugins/element.js'
import 'element-ui/lib/theme-chalk/display.css'
import 'element-ui/lib/theme-chalk/base.css'
import '@/assets/css/main.scss'
import myComponents from '@/components'
import '@/assets/icon/iconfont.css'
import api from '@/service'
import '@/directive'
import publicFunction from '@/utils/publicFunction.js'
import guide from '@/utils/guide.js'

// import BMap from 'vue-baidu-map'

Vue.use(myComponents)
Vue.use(GlobalConfig)
// Vue.use(BMap, {
//   ak: 'VL4FZwQgfIfC3hSOxvf3sdiDSe7elGwq'
// })

Vue.config.productionTip = false

Vue.prototype.$api = api
Vue.prototype.$publicFunction = publicFunction
Vue.prototype.$guide = guide

// 兼容ie的vue-router跳转
if (
  '-ms-scroll-limit' in document.documentElement.style &&
  '-ms-ime-align' in document.documentElement.style
) { // detect it's IE11
  window.addEventListener('hashchange', function (event) {
    var currentPath = window.location.hash.slice(1)
    if (store.state.route.path !== currentPath) {
      router.push(currentPath)
    }
  }, false)
}

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {
  // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent')
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val)

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null)

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal)
  }
}

Vue.prototype.removeItem = function(key) {
  return sessionStorage.removeItem(key)
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
