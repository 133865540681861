import axios from '../../plugins/axios'
import reAxios from '../../plugins/reAxios'
// 查询是否初始化
const checkInit = () => axios.get('/sys/sys-is-ready')
// 初始化
const init = (options) => axios.post('/sys/sys-init', options)
// 登录
const login = (options) => axios.post('/auth/auth-login', options)
// 注销
const logout = (options) => axios.post('/auth/auth-logout', options)
// 获取token
const refresh = (options) => reAxios.post('/auth/auth-refresh', options)
// 新手指引
const addGuide = (options) => reAxios.post('/guide/add-guide-user-record', options)
export default {
  login,
  logout,
  refresh,
  init,
  checkInit,
  addGuide
}
