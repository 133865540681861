import axios from '../../plugins/axios'

// 获取列表数据
// const queryData = (options) => axios.get('/api/v1/query', { params: options })
// const queryCate = (options) => axios.get('/api/v1/query', {
//   params: {
//     query: `sum(node_uname_info{})by(${options})`
//   }
// })
// const queryDataTotal = (options) => axios.get('/api/v1/query_range', { params: options })

const queryData = (options) => axios.post('/prometheus/query', options)
const queryCate = (options) => axios.post('/prometheus/query', { query: `sum(node_uname_info{${options.params}})by(${options.name})` })
const queryDataTotal = (options) => axios.post('/prometheus/query_range', options)

export default {
  queryData,
  queryCate,
  queryDataTotal
}
