import axios from '../../plugins/axios'
import deployAxios from '../../plugins/deployAxios'
// 获取节点列表
const getNodes = (options) => axios.post('/node/nodes', options)
// 获取节点信息
const nodeInfo = (options) => axios.post('/node/node', options)
// 获取客户端版本列表
const clientList = (options) => axios.get('/binary/client/list', options)
// 获取JDK版本列表
const envList = (options) => axios.get('/binary/env/list', options)
// 批量部署节点
const deploy = (options) => deployAxios.post('/binary/deploy', options)
// 分片名数组
const shard = (options) => axios.post('/node/shards', options)
export default {
  getNodes,
  nodeInfo,
  clientList,
  envList,
  deploy,
  shard
}
