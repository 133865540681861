// const baseUrl = '' // mock
const baseUrl = window.baseUrl
const uploadFileUrl = baseUrl + '/file/upload/img' // 文件上传地址
const downloadPdfUrl = baseUrl + '/file/upload/pdf' // PDF下载地址

export default {
  install (Vue, options) {
    Vue.prototype.BaseUrl = baseUrl
    Vue.prototype.uploadFileUrl = uploadFileUrl
    Vue.prototype.downloadPdfUrl = downloadPdfUrl
  },
  baseUrl: baseUrl,
  uploadFileUrl: uploadFileUrl,
  downloadPdfUrl: downloadPdfUrl
}
