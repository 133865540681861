import axios from '../../plugins/axios'
// 查询系统配置
const getConfig = () => axios.get('/sys/get-config')
// 修改系统配置
const updateConfig = (options) => axios.post('/sys/update-config', options)
// 查询系统信息
const getSystem = () => axios.get('/sys/get-system-config')
export default {
  getConfig,
  updateConfig,
  getSystem
}
