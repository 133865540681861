import Vue from 'vue'
// import { Router, createWebHistory } from 'vue-router'
import Router from 'vue-router'
import Layout from '@/components/Layout/index.vue'
import { getHeaderTitle } from '@/utils/localStorage.js'

Vue.use(Router)
// const history = createWebHistory()

const router = new Router({
  // history,
  routes: [{
    path: '/homelayout',
    component: Layout,
    redirect: '/home',
    children: [{
      path: '/home',
      name: 'home',
      component: () => import('../Overview/views/home/index.vue'),
      meta: {
        menuShow: true,
        breadcrumbShow: false,
        breadcrumb: false,
        title: '总览',
        icon: 'el-icon-pie-chart'
      }
    }]
  },
  {
    path: '/DataPlatform',
    component: Layout,
    meta: {
      title: '数据平台',
      icon: 'el-icon-data-line'
    },
    children: [
      // 链数据统计
      {
        path: 'statistics',
        name: 'statistics',
        component: () => import('../DataPlatform/views/chainData/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '链数据统计'
        }
      },
      {
        path: '/chainData/listblockMore',
        name: 'listblockMore',
        component: () => import('../DataPlatform/views/block/index.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '区块列表',
          parent: 'statistics'
        }
        // children: [
        //   {
        //     path: '/chainData/listblockMore/blockDetails',
        //     name: 'chainBlockDetails',
        //     component: () => import('../DataPlatform/views/block/details.vue'),
        //     hidden: true,
        //     meta: {
        //       menuShow: false,
        //       breadcrumbShow: true,
        //       title: '区块信息'
        //       // parent: 'listblockMore'
        //     }
        //   }
        // ]
      },
      {
        path: '/chainData/listLedgerMore',
        name: 'listLedgerMore',
        component: () => import('../DataPlatform/views/ledger/index.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本列表',
          parent: 'statistics'
        }
      },
      {
        path: '/chainData/details',
        name: 'chainDataDetails',
        component: () => import('../DataPlatform/views/chainData/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '链数据详情',
          parent: 'statistics'
        }
      },
      {
        path: '/chainData/listblockMore/blockDetails',
        name: 'chainBlockDetails',
        component: () => import('../DataPlatform/views/block/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '区块信息',
          parent: 'listblockMore'
        }
      },
      {
        path: '/chainData/listLedgerMore/ledgerDetails',
        name: 'chainLedgerDetails',
        component: () => import('../DataPlatform/views/ledger/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本信息',
          parent: 'listLedgerMore'
        }
      },
      // 区块列表
      {
        path: 'block',
        name: 'block',
        component: () => import('../DataPlatform/views/block/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '区块列表'
        }
      },
      {
        path: '/block/details',
        name: 'blockDetails',
        component: () => import('../DataPlatform/views/block/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '区块信息',
          parent: 'block'
        }
      },
      {
        path: 'ledger',
        name: 'ledger',
        component: () => import('../DataPlatform/views/ledger/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本列表'
        }
      },
      {
        path: '/ledger/details',
        name: 'ledgerDetails',
        component: () => import('../DataPlatform/views/ledger/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本信息',
          parent: 'ledger'
        }
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('../DataPlatform/views/account/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '记账列表'
        }
      },
      {
        path: '/account/details',
        name: 'accountDetails',
        component: () => import('../DataPlatform/views/account/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账户信息',
          parent: 'account'
        }
      },
      {
        path: 'workStatus',
        name: 'workStatus',
        component: () => import('../DataPlatform/views/workStatus/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '状态列表'
        }
      },
      {
        path: '/workStatus/details',
        name: 'workStatusDetails',
        component: () => import('../DataPlatform/views/workStatus/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '状态信息',
          parent: 'workStatus'
        }
      },
      {
        path: 'dictionary',
        name: 'dictionary',
        component: () => import('../DataPlatform/views/dictionary/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '数据字典'
        }
      },
      {
        path: 'collection',
        name: 'collection',
        component: () => import('../DataPlatform/views/collection/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '分片设置'
        }
      }
    ]
  },
  {
    path: '/OperationPlatform',
    component: Layout,
    meta: {
      title: '运维平台',
      icon: 'el-icon-s-data'
    },
    children: [
      {
        path: 'server',
        name: 'server',
        component: () => import('../OperationPlatform/views/serverStatus/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '节点监控'
        }
      },
      {
        path: 'node',
        name: 'node',
        component: () => import('../OperationPlatform/views/chainMonitor/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '链端监控'
        }
      },
      {
        path: 'node/detail',
        name: 'nodeDetail',
        hidden: true,
        component: () => import('../OperationPlatform/views/chainMonitor/detail.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '节点信息',
          parent: 'node'
        }
      },
      {
        path: 'warning',
        name: 'warning',
        component: () => import('../OperationPlatform/views/warning/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '性能预警'
        }
      },
      {
        path: 'deployment',
        name: 'deployment',
        component: () => import('../OperationPlatform/views/deployment/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '二进制部署'
        }
      }
    ]
  },
  {
    path: '/Middleware',
    component: Layout,
    meta: {
      title: '业务中间件',
      icon: 'ym-icon-shaixuan'
    },
    children: [
      {
        path: 'ledgerTask',
        name: 'ledgerTask',
        component: () => import('../Middleware/views/ledgerTask/list/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本任务'
        }
      },
      {
        path: 'ledgerTask/detail',
        name: 'ledgerTaskDetails',
        hidden: true,
        component: () => import('../Middleware/views/ledgerTask/details/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本任务详情',
          parent: 'ledgerTask'
        }
      },
      {
        path: 'contractTask',
        name: 'contractTask',
        component: () => import('../Middleware/views/contractTask/list/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '合约任务'
        }
      },
      {
        path: 'contractTask/detail',
        name: 'contractTaskDetails',
        hidden: true,
        component: () => import('../Middleware/views/contractTask/details/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '合约执行结果',
          parent: 'contractTask'
        }
      },
      {
        path: 'contractTask/deploy',
        name: 'contractTaskDeploy',
        hidden: true,
        component: () => import('../Middleware/views/contractTask/deploy/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '合约部署',
          parent: 'contractTask'
        }
      }
    ]
  },
  {
    path: '/SystemManagement',
    component: Layout,
    meta: {
      title: '系统管理',
      icon: 'ym-icon-xitong'
    },
    children: [
      {
        path: 'user',
        name: 'user',
        component: () => import('../System/views/user/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '用户管理'
        }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('../System/views/role/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '角色管理'
        }
      },
      {
        path: 'controls',
        name: 'controls',
        component: () => import('../System/views/controls/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '权限管理'
        }
      },
      {
        path: 'api',
        name: 'api',
        component: () => import('../System/views/api/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: 'API授权'
        }
      },
      {
        path: 'message',
        name: 'message',
        component: () => import('../System/views/message/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '消息管理'
        }
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import('../System/views/setting/index.vue'),
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '系统设置'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('../Common/views/login/index.vue'),
    meta: {
      menuShow: false,
      menuName: '登录'
    }
  },
  {
    path: '/',
    hidden: true,
    redirect: '/home',
    meta: {
      menuShow: false
    }
  }
  ]
})

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

/**
 * 设置浏览器头部标题
 */
export const setTitle = function (title) {
  title = title ? `${title}` : '区块链服务系统'
  window.document.title = title
}

router.afterEach(() => {
  setTimeout(() => {
    const browserHeaderTitle = getHeaderTitle()
    setTitle(browserHeaderTitle)
  }, 0)
})

export default router
