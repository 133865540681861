// 链上账本操作
import axios from '../../plugins/axios'
// 批量创建账本
const batch = (options) => axios.post('/api/ledger/batch', options)
// 查询某一个总任务回调情况
const callback = (options) => axios.get(`/api/ledger/callback-${options.overviewID}`)
// 获取任务详情列表
const detailList = (options, body) => axios.post(`/api/ledger/detail-list-${options.overviewID}-${options.size}-${options.page}`, body)
// 获取账本任务概览列表
const overviewList = (options, body) => axios.post(`/api/ledger/overview-list-${options.size}-${options.page}`, body)
// 查询某个总览任务进度情况
const overview = (options) => axios.get(`/api/ledger/overview-${options.overviewID}`)

// 新增合约监控
const taskAdd = (options) => axios.post('/api/contract/task-add', options)
// 获取合约任务列表
const taskList = (options, body) => axios.post(`/api/contract/task-list-${options.size}-${options.page}`, body)
// 获取触发器所需参数列表
const taskDetails = (options) => axios.get(`/api/contract/task-${options.taskID}`)
// 获取合约执行结果
const taskResult = (options, body) => axios.post(`/api/contract/task-result-${options.taskID}-${options.size}-${options.page}`, body)
// 获取触发器父类
const triggerType = () => axios.get('/api/contract/trigger-types')
// 获取触发器所需参数列表
const triggerArgs = (options) => axios.get(`/api/contract/trigger-args-${options.triggerID}`)
// 合约部署
const deploy = (options) => axios.post('/api/contract/deploy', options)
// 生成账户
const generate = (options) => axios.post('/api/contract/generate', options)
// 终止合约
const taskStop = (options) => axios.post(`/api/contract/task-pause-${options.taskID}`)
// 重启合约
const taskStart = (options) => axios.post(`/api/contract/task-resume-${options.taskID}`)

export default {
  batch,
  callback,
  detailList,
  overviewList,
  overview,
  taskAdd,
  taskList,
  taskDetails,
  taskResult,
  triggerType,
  triggerArgs,
  deploy,
  generate,
  taskStop,
  taskStart
}
