import axios from '../../plugins/axios'
// 查询告警信息列表（消息管理）
const listMsg = (options) => axios.post('/monitor/list-alert-msg', options)
// 查询告警列表
const listAlert = (options) => axios.post('/monitor/list-alert', options)
// 添加告警
const save = (options) => axios.post('/monitor/save-alert', options)
// 获取修改告警
const getAlert = (options) => axios.get(`/monitor/get-alert-${options.alertId}`)
// 修改告警
const update = (options) => axios.post('/monitor/update-alert', options)
// 获取监控指标数组
const target = () => axios.get('/monitor/list-alert-target')
// 获取告警模板
const tmpl = () => axios.get('/monitor/list-alert-tmpl')
export default {
  listMsg,
  listAlert,
  getAlert,
  save,
  update,
  target,
  tmpl
}
