import router from '../modulars/Common/router'
import store from '@/store'
// import { Message } from 'element-ui'
import { getToken } from './localStorage.js'

// 判断是否需要登录权限 以及是否登录
const accessList = ['/login', '/init', '/404']

router.beforeEach((to, from, next) => {
  store.commit('clearRequest') // 取消请求
  store.commit('emptyRequest') // 清空请求数组
  // next()
  if (getToken()) {
    // 有token
    if (to.name === 'login' || to.name === 'init') {
      store.dispatch('logout').then(() => {
        next()
      })
    } else {
      next()
    }
  } else if (accessList.includes(to.path)) {
    // 没有token
    next()
  } else {
    // Message.error('用户未登录，请重新登录')
    next({ name: 'login' }) // 否则全部重定向到登录页
  }
})
