'use strict'

// import Vue from 'vue'
import axios from 'axios'
import baseUrl from '@/config'
import { getToken } from '@/utils/localStorage.js'

let config = {
  baseURL: baseUrl.baseUrl,
  headers: {
    'Cache-Control': 'no-cache,no-store,max-age=-1,private',
    'Content-Type': 'application/json;charset=utf-8'
  }
}

const reAxios = axios.create(config)

reAxios.interceptors.request.use(
  config => {
    // 判断是否存在token，如果存在的话，则每个http header都加上token
    let token = JSON.parse(getToken())
    if (!config.headers.hasOwnProperty('Authorization') && token) {
      config.headers['Authorization'] = token.accessToken
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// Add a response interceptor
reAxios.interceptors.response.use(
  response => {
    // console.log('我要刷新token，其他地方不能刷')
    return response.data
  },
  error => {
    console.log('我是重复提交', error)
    return Promise.reject(error)
  }
)

// Plugin.install = function (Vue, options) {
//   Vue.axios = reAxios
//   window.axios = reAxios
//   Object.defineProperties(Vue.prototype, {
//     axios: {
//       get() {
//         return reAxios
//       }
//     },
//     $axios: {
//       get() {
//         return reAxios
//       }
//     }
//   })
// }

// Vue.use(Plugin)

export default reAxios
