import axios from '../../plugins/axios'
// 修改分片采集
const updateShard = (options) => axios.post('/sync-setting/update-sync-setting', options)
// 添加分片采集
const addShard = (options) => axios.post('/sync-setting/save-sync-setting', options)
// 查询分片同步设置
const getShardName = (options) => axios.get(`/sync-setting/get-sync-setting-${options.shardName}`)
// 查询分片同步设置
const getShardList = (options, body) => axios.post(`/sync-setting/list-sync-setting-${options.size}-${options.page}`, body)
export default {
  getShardName,
  getShardList,
  addShard,
  updateShard
}
