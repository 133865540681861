import axios from '../../plugins/axios'
// 集合统计
const aggregation = (options) => axios.post('/chainQL', { query: `
{
  # 区块高度
  listBlock(
    condition: {
      max:["height"]
      eq:{
        shardName:${JSON.stringify(options.shard)}
      }
    }
  )
  {
    total
    heightMax
  }

  # 合约总数
  listLedger(
    condition:{
      eq:{
        shardName:${JSON.stringify(options.shard)}
        ledgerCode:201
      }
    }
  )
  {
    total
  }
 }` })
// 账本数量
const actualDifficultyCount = (options) => axios.post('/chainQL', { query: `
{
  listLedger(
    condition:{
      eq:{
        shardName:${JSON.stringify(options.shard)}
      }
    }
  )
  {
    total
  }
}` })
// 账本类型数量
const ledgerAggregation = (options) => axios.post('/chainQL', { query: `
{
  listLedger(condition:{
    eq:{
        shardName:${JSON.stringify(options.shard)}
      }
    terms:"ledgerCode"
  })
  {
    ledgerCodeTerms {
      key
      value
    }
  }
}` })
// 账本处理笔数
const blockAggregation = (options) => axios.post('/chainQL', { query: `
{
  listLedger(condition:{
    eq:{
        shardName:${JSON.stringify(options.shard)}
      }
    terms:"timestamp"
    dataFormat:"${options.dataFormat}"
    size: ${options.size}
  }) {
    timestampTerms {
      key
      value
    }
  }
}` })
// 账本列表
const listLedger = (options) => axios.post('/chainQL', { query: `
{
  listLedger(
    condition: {
      descSorts: ["timestamp"]
      from:${options.page}
      size:${options.limit}
      eq:{
        shardName: ${JSON.stringify(options.shard)}
      }
    }
  )
  {
    total
    ledger {
      ledgerCode
      ledgerCodeName
      hash
      result
      timestamp
      shardName
    }
  }
}` })
// 区块列表
const listBlock = (options) => axios.post('/chainQL', { query: `
{
  listBlock(
    condition: {
      descSorts: ["timestamp"]
      from:${options.page}
      size:${options.limit}
      eq:{
        shardName: ${JSON.stringify(options.shard)}
      }
    }
  )
  {
    total
    block {
      shardName
      height
      hash
      difficulty
      actualDifficulty
      timestamp
    }
  }
}` })

export default {
  aggregation,
  actualDifficultyCount,
  ledgerAggregation,
  blockAggregation,
  listLedger,
  listBlock
}
